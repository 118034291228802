<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة موقع">
          <b-row>
            <b-col md="2" xl="2">
              <b-form-group>
                <b-form-checkbox
                  v-model="newcountry"
                  plain
                  class="custom-control-warning"
                >
                  <label> إضافة بلد</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newcountry">
            <b-col md="2" xl="2">
              <b-form-group label-for="delimiter">
                <label> اسم البلد</label>
                <b-form-input v-model="countryName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addCountry">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> اضافة البلد</span>
              </b-button>
            </b-col>
          </b-row>
            <br />
          <b-row>
            <b-col md="2" xl="2">
              <b-form-group>
                <b-form-checkbox
                  v-model="newgovernorate"
                  plain
                  class="custom-control-warning"
                >
                  <label> إضافة محافظة</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newgovernorate">
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> البلد</label>
                <v-select
                  v-model="selectedCountry"
                   :reduce="(val) => val.value"
                  :options="optionCountry"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="3" xl="3">
              <b-form-group label-for="delimiter">
                <label> المحافظة</label>
                <b-form-input v-model="governorateName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="addgovernorate">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> اضافة المحافظة</span>
              </b-button>
            </b-col>
          </b-row>
           <br />

          <b-row>
            <b-col md="2" xl="2">
              <b-form-group>
                <b-form-checkbox v-model="newCity" plain class="custom-control-warning">
                  <label> إضافة منطقة</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newCity">
            <b-col md="4" xl="4">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> البلد</label>
                <v-select
                  v-model="selectedOCounrtry"
                  :reduce="(val) => val.value"

                  :options="optionCountry"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> المحافظة</label>
                <v-select
                  v-model="selectedgovernorateCity"
                  :reduce="(val) => val.value"
                  :options="optionOgovernorate"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="3" xl="2">
              <b-form-group label-for="delimiter">
                <label> المنطقة</label>
                <b-form-input v-model="city" id="input-default" />
              </b-form-group>
            </b-col>
            <b-row>
              <b-col md="1" xl="3">
                <b-button variant="purple" @click="addCity">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle"> اضافة المنطقة</span>
                </b-button>
              </b-col>
            </b-row>
          </b-row>

          <br />
          <b-row>
            <b-col md="2" xl="2">
              <b-form-group>
                <b-form-checkbox v-model="newVillage" plain class="custom-control-warning">
                  <label> إضافة ناحية</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newVillage">
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> البلد</label>
                <v-select
                  v-model="selectCountryVillage"

                  :reduce="(val) => val.value"
@input="getoptionVgovernorate(selectCountryVillage)"

                  :options="optionCountry"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> المحافظة</label>
                <v-select
                  v-model="selectedCity"
                  :reduce="(val) => val.value"
                  @input="getoptionCity(selectedCity)"

                  :options="optionVgovernorate"

                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> المنطقة</label>
                <v-select
                  v-model="selectedCityVillage"
                  :reduce="(val) => val.value"
                  :options="optionVCity"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="3" xl="2">
              <b-form-group label-for="delimiter">
                <label> الناحية</label>
                <b-form-input v-model="VillageName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-row>
              <b-col md="1" xl="3">
                <b-button variant="purple" @click="addVillage">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle"> اضافة الناحية</span>
                </b-button>
              </b-col>
            </b-row>
          </b-row>

          <br />
           <b-row>
            <b-col md="2" xl="2">
              <b-form-group>
                <b-form-checkbox v-model="newtown" plain class="custom-control-warning">
                  <label> إضافة حي</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
           <b-row v-if="newtown">
            <b-col md="4" xl="4">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="townbycountry">
                <label> البلد</label>
                <v-select
                  v-model="selectCountryTown"
                  :reduce="(val) => val.value"
                  @input="getoptionTgovernorate(selectCountryTown)"

                  :options="optionCountry"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> المحافظة</label>
                <v-select
                  v-model="selectedTownCity"
                  :reduce="(val) => val.value"
                  @input="getoptionTCity(selectedTownCity)"
                  :options="optionTgovernorate"

                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>
            <b-col md="4" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="townbycity">
                <label> المنطقة</label>
                <v-select
                  v-model="selectedOldArea"
                  :reduce="(val) => val.value"
                  :options="optionCity"

                  @input="getoptionTArea(selectedOldArea)"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="3" xl="2">
              <b-form-group label-for="townbyvillage">
                <label> الناحية</label>
                  <v-select v-model="selectedVillageT"    :reduce="(val) => val.value" :options="optionTVillages" />
              </b-form-group>
            </b-col>
              <b-col md="3" xl="2">
              <b-form-group label-for="newtown">
                <label> الحي</label>
                <b-form-input v-model="TownName" id="input-default" />
              </b-form-group>
            </b-col>
            <b-row>
              <b-col md="1" xl="3">
                <b-button variant="purple" @click="addTown">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle"> اضافة الحي</span>
                </b-button>
              </b-col>
            </b-row>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { location } from "@/@core/mixins/location.js";
export default {
  name: "add-location",
  mixins: [location],
  components: {
    BDropdown,

    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      newcountry: false,
      newgovernorate: false,
      newCity: false,
      newVillage: false,
      newtown:false,
      countryName: '',
      TownName:'',
      governorateName: '',
      VillageName: '',
      city: '',
      optionCountry: [],
      optiongovernorate: [],
      optionCity: [],
      optionVCity:[],
      selectedCountry: '',
      selectedTownCity:'',
      selectedgovernorateVillage: '',
      selectCountryCities: '',

      selectedCityVillage: '',
      selectedgovernorateCity: '',

      selectedgovernorate: '',
      optionTVillages:[]
    };
  },

  computed: {},

  created() {
    this.getCountry();
  },

  methods: {

    addCountry() {
      let data = {
        name: this.countryName,
      };
      this.$http.post("/api/v1/countries", data).then((res) => {
        //console.log(res);

             this.$swal({
                  title: "تم إضافة البلد بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
      }).catch((error) => {
            //console.log(error.response.data.message);
              this.$swal({
                  title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
          });

    },
    getCountry() {
      this.$http.get("/api/v1/countries").then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
          this.optionCountry.push({ value: el.id, label: el.name });
        });
      });
    },

    getoptionVgovernorate(id){
        this.optionVgovernorate=[]
        let url = `/api/v1/countries/${id}/governorates`;
        this.$http.get(url).then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
          this.optionVgovernorate.push({ value: el.id, label: el.name });
        });
      });

    },
    getoptionCity(id){
        this.optionVCity=[]
        //console.log("dd", this.optionVCity)
        let url = `/api/v1/governorates/${id}/cities`;
        this.$http.get(url).then((res) => {
        let country = res.data.data;
        //console.log("dd",res.data.data)
        //console.log("dd", this.optionVCity)
        res.data.data.forEach((el) => {
            //console.log("el", el)
          this.optionVCity.push({ value: el.id, label: el.name });
        });
      });

    },
    getoptionTgovernorate(id){
        this.optionTgovernorate=[]
        let url = `/api/v1/countries/${id}/governorates`;
        this.$http.get(url).then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
          this.optionTgovernorate.push({ value: el.id, label: el.name });
        });
      });
    },
    getoptionTCity(id){
        this.optionCity=[]
        let url = `/api/v1/governorates/${id}/cities`;
        this.$http.get(url).then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
          this.optionCity.push({ value: el.id, label: el.name });
        });
      });

    },
    getoptionTArea(id){
        this.optionTVillages=[]
        let url = `/api/v1/cities/${id}/towns`;

        this.$http.get(url).then((res) => {
        let country = res.data.data;
        country.forEach((el) => {
            //console.log(el)
          this.optionTVillages.push({ value: el.id, label: el.name });
        });
      });

    },


  addTown(){
    let data = {
        name: this.selectedVillageT,
        neighborhood_id: this.TownName,
      };
      //console.log(data);
      let url=`/api/v1/streets?name=${this.TownName}&neighborhood_id=${this.selectedVillageT}`
      this.$http.post(url).then((res) => {
         this.$swal({
                  title: "تم إضافة الحي بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });


      }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
                  title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });})
  },
    addgovernorate() {
      let data = {
        name: this.governorateName,
        country_id: this.selectedCountry,
      };
      //console.log(data);
      this.$http.post("/api/v1/governorates", data).then((res) => {
       this.$swal({
                  title:  "تم إضافة المحافظة بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

      }).catch((error) => {
            //console.log(error.response.data.message);
              this.$swal({
                  title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
          });

    },
    addCity() {
      let data = {
        name: this.city,
        governorate_id: this.selectedgovernorateCity,
      };
      //console.log(data);
      this.$http.post("/api/v1/cities", data).then((res) => {
         this.$swal({
                  title: "تم إضافة المدينة بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });


      }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
                  title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });})
    },
    addVillage() {
      let data = {
        name: this.VillageName,
        city_id: this.selectedCityVillage,
      };
      //console.log(data);
      this.$http.post("/api/v1/towns", data).then((res) => {
        this.$swal({
                  title: "تم إضافة الناحية بنجاح",

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

      }).catch((error) => {
            //console.log(error.response.data.message);
            this.$swal({
                  title: `${error.response.data.message}`,

                icon: "success",
                confirmButtonText: "موافق",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              })
          });
    },
    //
  }
};
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
